const Advantage: React.FC<{ title: string; imageUrl: string }> = ({
	title,
	imageUrl,
	children,
}) => (
	<section>
		<div>
			<h4>{title}</h4>
			<p>{children}</p>
		</div>
		<img src={imageUrl} />
		<style jsx>{`
			section {
				display: flex;
				justify-content: space-around;
				max-width: 900px;
				width: 90%;
				margin: 0 auto;
				margin-top: 60px;
				padding-bottom: 10px;
			}
			h4 {
				font-size: 20px;
				margin-bottom: 15px;
				margin-top: 27px;
				font-weight: 600;
			}
			p {
				font-size: 16px;
				line-height: 24px;
				margin: 0 auto;
			}
			img {
				width: 434px;
				flex-shrink: 1;
			}

			@media (max-width: 767px) {
				section {
					margin-top: 30px;
					width: 100%;
					text-align: center;
					padding: 0 10px;
					flex-direction: column;
					align-items: center;
				}
				h4 {
					width: 100%;
					font-size: 18px;
					line-height: 22px;
				}
				p {
					max-width: 600px;
					font-size: 15px;
					line-height: 22px;
				}
				img {
					order: 1;
					width: 100%;
				}
				div {
					order: 2;
				}
			}
		`}</style>
	</section>
)

export default Advantage
