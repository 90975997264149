import { useState, useEffect } from 'react'
import Input from './Input'

const ESC_KEY = 27
const UP_KEY = 38
const DOWN_KEY = 40
const ENTER_KEY = 13

type Props = {
	getAutoComplete: (input: string) => Promise<string[]>
} & React.ComponentProps<'input'>

const AutoCompleteInput: React.FC<Props> = ({ getAutoComplete, children, ...props }) => {
	const [showAutoComplete, setShowAutoComplete] = useState(false)
	const [autoComplete, setAutoComplete] = useState<string[]>([])
	const [selected, setSelected] = useState(-1)
	const [value, setValue] = useState(props.defaultValue || '')
	let unmounted = false
	useEffect(
		() => () => {
			unmounted = true
		},
		[],
	)
	const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
		if (event.keyCode === ESC_KEY && showAutoComplete) {
			event.preventDefault()
			event.stopPropagation()
			setShowAutoComplete(false)
			setSelected(-1)
			return true
		} else if (event.keyCode === 13) {
			if (selected > -1) {
				event.preventDefault()
				event.stopPropagation()
				setValue(autoComplete[selected])
				setShowAutoComplete(false)
				setSelected(-1)
				return true
			}
		}
	}

	const onKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
		const query = event.currentTarget.value
		const { keyCode } = event
		if (!query || keyCode === ESC_KEY || keyCode === ENTER_KEY) {
			setShowAutoComplete(false)
			return
		}

		if (keyCode === DOWN_KEY) {
			setSelected((selected + 1) % autoComplete.length)
			return
		} else if (keyCode === UP_KEY) {
			setSelected((selected - 1) % autoComplete.length)
			return
		}

		if (event.target) {
			getAutoComplete((event.target as HTMLInputElement).value).then(ac => {
				if (unmounted) {
					return
				}
				setAutoComplete(ac || [])
				setShowAutoComplete(true)
			})
		}

		return true
	}

	const onSelect = (ac: string) => (event: React.SyntheticEvent) => {
		event.stopPropagation()
		event.preventDefault()
		setValue(ac)
		setShowAutoComplete(false)
	}

	const touchHandlers = (ac: string) => {
		let dragging = false
		return {
			onTouchStart: () => {
				dragging = false
			},
			onTouchMove: () => {
				dragging = true
			},
			onTouchEnd: (event: React.TouchEvent) => {
				if (!dragging) {
					onSelect(ac)(event)
				}
			},
		}
	}

	return (
		<div
			onBlur={() => {
				setShowAutoComplete(false)
			}}
		>
			<Input
				{...props}
				autoComplete="off"
				defaultValue={undefined}
				value={value}
				onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
					setValue(event.currentTarget.value)
				}}
				onKeyDown={onKeyDown}
				onKeyUp={onKeyPress}
				onFocus={(event: React.FocusEvent<HTMLInputElement>) => {
					getAutoComplete(event.currentTarget.value).then(setAutoComplete)
					setShowAutoComplete(true)
				}}
			/>
			<ul className={showAutoComplete && autoComplete.length > 0 ? 'show' : ''}>
				{autoComplete.map((ac, index) => (
					<li
						className={index === selected ? 'selected' : ''}
						key={ac}
						onMouseDown={onSelect(ac)}
						{...touchHandlers(ac)}
					>
						{ac}
					</li>
				))}
			</ul>
			{children}
			<style jsx>{`
				div {
					position: relative;
					display: flex;
				}
				ul {
					box-shadow: -7px 6px 26px -11px rgba(122, 122, 122, 1);
					box-shadow: 0px 3px 21px -2px rgba(122, 122, 122, 1);
					display: none;
					margin-top: 5px;
					list-style: none;
					background: white;
					position: absolute;
					top: 100%;
					z-index: 1000;
					left: 0;
					right: 0;
					border: 1px solid rgb(226, 226, 226);
					border-radius: 5px;
				}
				ul.show {
					display: block;
				}
				li {
					padding: 10px;
				}
				li + li {
					border-top: 1px solid rgb(226, 226, 226);
				}
				li.selected {
					background: lightgray;
				}
			`}</style>
		</div>
	)
}

export default AutoCompleteInput
