export const More = () => (
	<svg viewBox="0 0 30 40" xmlns="http://www.w3.org/2000/svg">
		<circle cx="5" cy="10" r="3" />
		<circle cx="15" cy="10" r="3" />
		<circle cx="25" cy="10" r="3" />
	</svg>
)

export const Loading = () => (
	<div className="loader">
		<style jsx>{`
			.loader,
			.loader:after {
				border-radius: 50%;
				width: 100%;
				height: 100%;
			}
			.loader {
				position: relative;
				text-indent: -9999em;
				border-top: 5px solid rgba(255, 255, 255, 0.2);
				border-right: 5px solid rgba(255, 255, 255, 0.2);
				border-bottom: 5px solid rgba(255, 255, 255, 0.2);
				border-left: 5px solid #ffffff;
				transform: translateZ(0);
				animation: load8 1.1s infinite linear;
			}
			@keyframes load8 {
				0% {
					-webkit-transform: rotate(0deg);
					transform: rotate(0deg);
				}
				100% {
					-webkit-transform: rotate(360deg);
					transform: rotate(360deg);
				}
			}
		`}</style>
	</div>
)

export const BarChartIcon = () => (
	<svg
		width="20px"
		height="20px"
		xmlns="http://www.w3.org/2000/svg"
		xmlnsXlink="http://www.w3.org/1999/xlink"
		viewBox="0 0 110 110"
		version="1.1"
		x="0px"
		y="0px"
	>
		<title>Chart</title>
		<desc>Created with Sketch.</desc>
		<g stroke="none" strokeWidth="1" fillRule="evenodd">
			<g transform="translate(6.000000, 17.000000)">
				<path d="M4,23.1304348 L22.4,23.1304348 C24.609139,23.1304348 26.4,24.9212958 26.4,27.1304348 L26.4,72 C26.4,74.209139 24.609139,76 22.4,76 L4,76 C1.790861,76 2.705415e-16,74.209139 0,72 L0,27.1304348 C-2.705415e-16,24.9212958 1.790861,23.1304348 4,23.1304348 Z M40.3,-3.55271368e-15 L58.7,-3.55271368e-15 C60.909139,-3.95852593e-15 62.7,1.790861 62.7,4 L62.7,72 C62.7,74.209139 60.909139,76 58.7,76 L40.3,76 C38.090861,76 36.3,74.209139 36.3,72 L36.3,4 C36.3,1.790861 38.090861,-3.14690143e-15 40.3,-3.55271368e-15 Z M76.6,36.3478261 L95,36.3478261 C97.209139,36.3478261 99,38.1386871 99,40.3478261 L99,72 C99,74.209139 97.209139,76 95,76 L76.6,76 C74.390861,76 72.6,74.209139 72.6,72 L72.6,40.3478261 C72.6,38.1386871 74.390861,36.3478261 76.6,36.3478261 Z"></path>
			</g>
		</g>
		<style jsx>{`
			svg {
				fill: #ffcc00;
			}
		`}</style>
	</svg>
)
