import { NextPage } from 'next'
import { useTrackPageImpression } from 'shared/customHooks'
import { IndexPageProps as Props } from 'shared/interfaces'
import indexView from 'shared/views/index'
import Home from '../components/Home'

const IndexPage: NextPage<Props> = props => {
	useTrackPageImpression(
		props.seoStartPage.PageProperties.Title,
		props.asPath,
		props.query,
	)
	return <Home {...props} />
}

IndexPage.getInitialProps = async ctx => {
	const props = await indexView(ctx)
	return props
}

export default IndexPage
