import AnimatedCounter from './AnimatedCounter'
import { IJobIndex } from 'shared/interfaces'

const MarketingNumbers: React.FC<IJobIndex> = ({
	totalFilterOptions,
	totalJobs,
	totalSources,
}) => (
	<div>
		<h3>Einfach zum richtigen Job</h3>
		<ul>
			<li>
				<span>Wir finden heute</span>
				<span>
					<AnimatedCounter count={totalJobs} />
				</span>
				<span>Jobs für dich</span>
			</li>
			<li>
				<span>Wir durchsuchen für dich</span>
				<span>
					<AnimatedCounter count={totalSources} />
				</span>
				<span>Job-Börsen und Unternehmensseiten</span>
			</li>
			<li>
				<span>Wir bieten dir</span>
				<span>
					<AnimatedCounter count={totalFilterOptions} />
				</span>
				<span>Filteroptionen</span>
			</li>
		</ul>
		<style jsx>{`
			div {
				background: 'f4f3f3';
				padding-top: 60px;
				padding-bottom: 70px;
				text-align: center;
			}
			h3 {
				font-size: 26px;
				font-weight: 500;
				padding-bottom: 20px;
				color: #333;
				font-weight: 400;
			}
			ul {
				display: flex;
				justify-content: space-around;
				flex-wrap: wrap;
			}
			li {
				display: flex;
				flex-direction: column;
				padding-top: 30px;
				width: 220px;
				color: #333;
			}
			span:first-child,
			span:last-child {
				width: 190px;
				margin: 0 auto;
				line-height: 1.4;
				font-size: 15px;
			}
			span:first-child {
				font-weight: 600;
			}
			span:last-child {
				color: #666;
			}

			span:nth-child(2) {
				font-size: 40px;
				margin-bottom: 5px;
				margin-top: 20px;
			}
			span:nth-child(2)::after {
				content: '';
				background: #aacc00;
				display: block;
				width: 60px;
				height: 3px;
				margin: 14px auto;
				color: #444;
			}

			@media (max-width: 767px) {
				div {
					padding-bottom: 60px;
				}
				h3 {
					font-size: 24px;
					padding-top: 0;
					padding-bottom: 10px;
				}
				li {
					padding-top: 50px;
				}
				span:nth-child(2) {
					margin-bottom: 0;
					padding-bottom: 6px;
				}
			}
		`}</style>
	</div>
)

export default MarketingNumbers
