import { IStoredSearch, ITermItem } from 'shared/interfaces'
import { withRouter, SingletonRouter } from 'next/router'
import Link from 'next/link'
import { extendQuery } from 'shared/Util'
import { linkAccentColor } from 'config/style'
import translate from 'config/translate'

type Props = {
	storedSearches: IStoredSearch[]
	router: SingletonRouter
	lang: string
}

export const StoredSearchElement: React.FC<{
	searchTerms: ITermItem[]
	router: SingletonRouter
}> = ({ searchTerms, router }) => {
	const searchByCategory: {
		location?: string
		radiusInKm?: string
		freeTextInput?: string
		jobcenterFreeTextInput?: string
	} = searchTerms.reduce(
		(prev: { [key: string]: string }, { termDisplayName, category }) => {
			if (termDisplayName) {
				prev[category] = termDisplayName
			}

			return prev
		},
		{},
	)

	let storedSearchesString = ''
	if (searchByCategory.freeTextInput) {
		storedSearchesString += searchByCategory.freeTextInput
	}

	if (searchByCategory.jobcenterFreeTextInput) {
		storedSearchesString += searchByCategory.jobcenterFreeTextInput
	}

	if (searchByCategory.location) {
		storedSearchesString += ' in ' + searchByCategory.location
	}

	if (searchByCategory.radiusInKm && searchByCategory.radiusInKm !== '0') {
		storedSearchesString += ' (' + searchByCategory.radiusInKm + ' km)'
	}

	return (
		<>
			<Link
				href={{
					pathname: '/search',
					query: extendQuery(router.query, {
						q:
							searchByCategory.freeTextInput ||
							searchByCategory.jobcenterFreeTextInput,
						loc: searchByCategory.location,
						r: searchByCategory.radiusInKm,
					}),
				}}
			>
				<a rel="nofollow">{storedSearchesString}</a>
			</Link>
			<style jsx>{`
				a {
					background: none;
					color: #fff;
					cursor: pointer;
					user-select: none;
					border: none;
					text-decoration: none;
					padding: 0 5px;
				}

				a + a {
					border-left: 2px solid white;
				}

				a:hover {
					color: ${linkAccentColor};
				}

				@media (max-width: 1279px) {
					a {
						display: none;
					}

					a:first-of-type {
						display: inline-block;
					}
				}

				@media (max-width: 700px) {
					a {
						margin-top: 12px;
						font-size: 16px;
						font-weight: 600;
					}
					a:first-of-type {
						padding-left: 0;
					}
				}
			`}</style>
		</>
	)
}

export const StoredSearches: React.FC<Props> = ({
	storedSearches = [],
	router,
	lang,
}) => {
	if (storedSearches.length === 0) {
		return null
	}

	return (
		<>
			<div>
				<h4>{translate('DEINE LETZTE SUCHE', lang)}</h4>
				{storedSearches.slice(0, 3).map((search, index) => (
					<StoredSearchElement
						searchTerms={search.searchTerms}
						router={router}
						key={index}
					/>
				))}
			</div>

			<style jsx>{`
				div {
					margin: 0 auto;
				}
				h4 {
					color: #fff;
					font-size: 14px;
					font-weight: 600;
					user-select: none;
					margin-right: 10px;
					display: inline;
				}
				@media (max-width: 700px) {
					h4 {
						display: block;
						width: 100%;
						font-size: 16px;
						margin: 5px 0;
					}
					div {
						width: calc(100vw - 40px);
						font-size: 16px;
						margin: 0;
						margin-top: 10px;
						max-width: 400px;
					}
				}
			`}</style>
		</>
	)
}

export default withRouter(StoredSearches)
