import Carousel from './Carousel'
import Advantage from './Advantage'

const Advantages = () => (
	<section>
		<h3>Wie kimeta dir die Jobsuche erleichtert</h3>
		<div>
			<Carousel>
				<Advantage
					title="Mit kimeta hast du alles im Blick"
					imageUrl="/Onboarding-Startseite-01.png"
				>
					Mit kimeta kannst du auf allen bekannten Jobbörsen, Jobportalen sowie
					unzähligen Unternehmensseiten gleichzeitig nach deinem Traumjob suchen
					- und so jede Menge Zeit bei der Jobsuche sparen.
				</Advantage>
				<Advantage
					title="kimeta stellt sich auf dich ein"
					imageUrl="/Onboarding-Startseite-03.png"
				>
					Bei kimeta kannst du Stellenanzeigen nicht nur nach bestimmten
					Stichworten durchsuchen, sondern diese auch gezielt ausschließen - zum
					Beispiel wenn du kein <strong>Englisch</strong> sprichst oder einzelne
					Fähigkeiten nicht beherrschst.
				</Advantage>
				<Advantage
					title="Mit kimeta behältst du den Überblick"
					imageUrl="/Onboarding-Startseite-04.png"
				>
					Die wichtigsten Stichworte einer Stellenanzeige fassen wir für dich in
					einer übersichtlichen Tag-Cloud zusammen - so kannst du die
					Jobangebote schneller erfassen und einen ersten Eindruck gewinnen, ob
					der Job überhaupt zu dir passt.
				</Advantage>
			</Carousel>
		</div>
		<style jsx>{`
			h3 {
				color: #333;
				text-align: center;
				font-size: 26px;
				padding-top: 60px;
				padding-bottom: 10px;
				line-height: 1.2;
				font-weight: 400;
			}
			section {
				background: white;
			}
			div {
				margin: 0 auto;
				margin-bottom: 14px;
				padding-bottom: 60px;
				background: white;
				max-width: 1100px;
			}

			@media (max-width: 767px) {
				h3 {
					font-size: 23px;
					padding-top: 40px;
					padding-bottom: 0;
					padding-left: 20px;
					padding-right: 20px;
				}
				div {
					padding-bottom: 30px;
				}
			}

			@media (min-width: 769px) {
				section {
					order: 2;
				}
			}
		`}</style>
	</section>
)

export default Advantages
