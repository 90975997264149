import { accentColor as focusColor } from 'config/style'
export const distances = [0, 10, 20, 30, 50, 100]

export type Props = Readonly<{
	radius: number
}> &
	React.ComponentProps<'select'>

export const DistanceSelect: React.FC<Props> = ({ radius, ...rest }) => (
	<span>
		<select name="r" defaultValue={radius.toString()} {...rest}>
			{distances.map(distance => (
				<option key={distance} value={distance}>
					{distance}km
				</option>
			))}
		</select>
		<style jsx>{`
			:global(:placeholder-shown ~ span) {
				display: none;
			}

			select {
				height: 40px;
				border: 1px solid rgb(226, 226, 226);
				border-radius: 0px;
				border-top-right-radius: 5px;
				border-bottom-right-radius: 5px;
				font-size: 16px;
				width: 75px;
				outline: none;
				padding-left: 8px;
				appearance: none;
				background-color: rgb(245, 239, 239);
				background-image: url('data:image/svg+xml;utf8,<svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"  width="255px" height="255px" viewBox="0 0 255 255" style="enable-background:new 0 0 255 255;" xml:space="preserve"> <g> <g id="arrow-drop-down"> <polygon points="0,63.75 127.5,191.25 255,63.75 "/> </g> </g> </svg>');
				background-repeat: no-repeat, repeat;
				background-position: right 0.3em top 50%, 0 0;
				background-size: 0.65em auto, 100%;
				padding-right: 7px;
				margin-left: -5px;
			}
			select:focus {
				border: 1px solid ${focusColor};
			}
			option:checked {
				background: rgb(180, 204, 4);
			}
		`}</style>
	</span>
)

export default DistanceSelect
