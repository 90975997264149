import { SeoStartPage, IJobIndex, SeoLinkDescription } from 'shared/interfaces'
import { parseSeoText } from 'shared/Util'
import SeoLink from './SeoLink'

type Props = Readonly<{ seoStartPage: SeoStartPage; jobIndex: IJobIndex }>

const SeoBlockStartpage: React.FC<Props> = props => {
	const { seoStartPage: seoStartpage, jobIndex } = props

	return (
		<>
			<div className="seo-text">
				<h3
					dangerouslySetInnerHTML={{
						__html: parseSeoText(
							seoStartpage.SeoTextHeading,
							jobIndex.totalJobs,
						),
					}}
				/>
				<div
					dangerouslySetInnerHTML={{
						__html: parseSeoText(seoStartpage.SeoText, jobIndex.totalJobs),
					}}
				/>
			</div>
			<div className="seo-links">
				<LinkList links={seoStartpage.TopSearches} />
				<LinkList links={seoStartpage.LinksToBrowsingPages} />
			</div>
			<style jsx>{`
				.seo-links {
					padding: 40px 20px 60px;
				}

				.seo-text {
					padding: 0 15px;
				}

				.seo-links div,
				.seo-text div,
				.seo-text h3 {
					text-align: center;
				}
				.seo-text h3 :global(strong) {
					font-weight: inherit;
				}

				.seo-text div {
					line-height: 24px;
				}

				.seo-links {
					display: block;
				}

				.seo-links-mobile {
					display: none;
				}

				.seo-text h3 {
					color: #333;
					font-size: 26px;
					line-height: 1.2;
					font-weight: 400;
					padding: 0 20px;
					padding: 60px 20px;
				}

				.seo-text :global(a) {
					color: inherit;
					text-decoration: none;
				}

				.seo-text div :global(strong) {
					font-weight: bold;
				}

				@media (max-width: 767px) {
					.seo-text,
					.seo-links {
						background-color: white;
					}
					.seo-text div {
						line-height: 22px;
					}

					.seo-links {
						display: flex;
						flex-direction: column;
						padding: 30px 15px;
						background-color: white;
					}

					.seo-links > :global(ul:first-child) {
						order: 2;
					}
				}
			`}</style>
		</>
	)
}

export default SeoBlockStartpage

export const LinkList: React.FC<{ links: SeoLinkDescription[] }> = ({ links }) => (
	<ul>
		{links.map((el, index) => (
			<li key={index}>
				<SeoLink seoLink={el} />
			</li>
		))}
		<style jsx>{`
			ul {
				display: flex;
				margin: 0 auto;
				list-style: none;
				margin-bottom: 5px;
				flex-wrap: wrap;
				justify-content: center;
				font-size: 14px;
			}

			li {
				padding: 0 6px;
				line-height: 1;
				border-right: 1px solid #555555;
				margin-top: 5px;
			}

			li:last-child {
				border-right: none;
			}

			li :global(a) {
				color: #aacc00;
				text-decoration: none;
			}

			li :global(a:hover) {
				text-decoration: underline;
				color: rgb(90, 120, 29);
			}

			@media (max-width: 767px) {
				ul {
					width: 100%;
					margin-bottom: 0;
				}

				li {
					padding: 0;
					width: 50%;
					border: none;
					line-height: 2;
				}

				li:nth-child(even) {
					text-align: right;
				}
			}
		`}</style>
	</ul>
)
