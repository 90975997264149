import React from 'react'
import { primaryColor } from '../styles'

const Carousel: React.FC = ({ children }) => {
	const count = React.Children.count(children)
	const ids = new Array(count).fill(1).map((_, index) => `c${index}`)
	return (
		<div>
			{ids.map((id, index) => (
				<Input id={id} key={id} index={index} count={count} />
			))}

			{ids.map((id, index) => (
				<PrevNextLabels
					key={id}
					id={id}
					prevId={ids[index - 1]}
					nextId={ids[index + 1]}
				/>
			))}
			<ul>
				{React.Children.map(children, (child, index) => (
					<li key={index}>{child}</li>
				))}
			</ul>
			<nav>
				{ids.map(id => (
					<Label htmlFor={id} key={id} />
				))}
			</nav>
			<style jsx>{`
				input {
					display: none;
				}
				div {
					overflow: hidden;
					position: relative;
					width: 100%;
				}
				ul {
					list-style: none;
					display: flex;
					width: ${count * 100}%;
					transform: translateX(0px);
					transition: all 0.3s;
				}

				li {
					width: 100%;
				}

				nav {
					justify-content: space-between;
					margin: 0 auto;
					display: flex;
					justify-content: center;
				}
			`}</style>
		</div>
	)
}

const Input: React.FC<{ id: string; index: number; count: number }> = ({
	id,
	index,
	count,
}) => (
	<>
		<input id={id} type="radio" name="carousel" defaultChecked={index === 0} />
		<style jsx>{`
			input {
				display: none;
			}
			:global(#${id}:checked ~ ul) {
				transform: translateX(${(-100 * index) / count}%);
			}
		`}</style>
	</>
)

const Label: React.FC<{ htmlFor: string }> = ({ htmlFor }) => (
	<>
		<label htmlFor={htmlFor} />
		<style jsx>{`
			label {
				-webkit-tap-highlight-color: transparent;
				width: 10px;
				height: 10px;
				border: 10px solid white;
				margin: 4px;
				border-radius: 50%;
				background: #f4f3f3;
				cursor: pointer;
			}

			:global(#${htmlFor}:checked ~ nav label[for="${htmlFor}"]) {
				background: ${primaryColor};
			}

			@media (max-width: 767px) {
				label {
					width: 12px;
					height: 12px;
				}
			}
		`}</style>
	</>
)

const PrevNextLabels: React.FC<{ id: string; prevId: string; nextId: string }> = ({
	id,
	prevId,
	nextId,
}) => (
	<>
		{prevId !== undefined ? (
			<label className="prev" htmlFor={prevId}>
				<span />
			</label>
		) : null}
		{nextId ? (
			<label className="next" htmlFor={nextId}>
				<span />
			</label>
		) : null}
		<style jsx>{`
			label {
				position: absolute;
				top: 50%;
				display: flex;
				align-items: center;
				width: 20px;
				height: 20px;
				border-radius: 50%;
				margin: 10px;
				padding: 4px;
				transform: translateY(-50%);
				background: #f4f3f3;
				z-index: 1;
				cursor: pointer;
			}

			.prev {
			left: 0;
			}

			.next {
			right: 0;
			}

			span {
				display: block;
				width: 8px;
				height: 8px;
				border-left: 2px solid rgb(142, 164, 7);
				border-top: 2px solid rgb(142, 164, 7);
				border-radius: 2px;
				position: relative;
				right: -3px;
				transform: rotate(135deg);
			}

			.prev span {
				right: 0;
				left: 6px;
				transform: rotate(-45deg);
			}

			:global(#${id}:checked ~ .prev:not([for="${prevId}"])) {
				display: none;
			}

			:global(#${id}:checked ~ .next:not([for="${nextId}"])) {
				display: none;
			}

			@media (max-width: 400px) {
				label {
					transform: translateY(-40px);
				}
			}
		
			@media (max-width: 370px) {
				label {
					transform: translateY(-60px);
				}
			}
		`}</style>
	</>
)

export default Carousel
