import { getAutoCompleteJob, getAutoCompleteLocation } from 'shared/api'
import { IndexPageProps } from 'shared/interfaces'
import ActionButton from 'shared/components/ActionButton'
import Advantages from 'shared/components/advantages/Advantages'
import AutoCompleteInput from 'shared/components/AutoCompleteInput'
import DistanceSelect from 'shared/components/DistanceSelect'
import Footer from 'shared/components/Footer'
import Form from 'shared/components/Form'
import Header from 'shared/components/Header'
import MarketingNumbers from 'shared/components/marketingNumbers/MarketingNumbers'
import StoredSearches from 'shared/components/storedSearches/StoredSearches'
import Head from 'next/head'
import SeoBlockStartpage from './SeoBlockStartpage'

const Home: React.FC<IndexPageProps> = ({
	jobIndex,
	storedSearches,
	notedOffers,
	seoStartPage,
	isUserLoggedIn,
	asPath,
}) => (
	<>
		<Head>
			<title key="title">{seoStartPage.PageProperties.Title}</title>
			<meta
				name="keywords"
				content={seoStartPage.PageProperties.Keywords}
				key="keywords"
			/>
			<meta
				name="description"
				content={seoStartPage.PageProperties.Description.replace(
					'{Trefferzahl}',
					jobIndex.totalJobs.toLocaleString('de').replace(/,/g, '.'),
				)}
				key="description"
			/>
		</Head>
		<Header
			count={notedOffers.length}
			redirectPath={asPath}
			isUserLoggedIn={isUserLoggedIn}
			asPath={asPath}
		/>
		<div className="hero">
			<Form action="/search" scrollTop={true} method="get" name="searchForm">
				<div className="container">
					<div className="row">
						<div>
							<label htmlFor="search">Was?</label>
							<div className="input">
								<AutoCompleteInput
									type="search"
									name="q"
									placeholder="Jobtitel oder Stichwort eingeben"
									id="search"
									getAutoComplete={getAutoCompleteJob}
								/>
							</div>
						</div>
						<div>
							<label htmlFor="loc">Wo?</label>
							<div className="input">
								<AutoCompleteInput
									type="search"
									name="loc"
									placeholder="Ort oder PLZ eingeben"
									getAutoComplete={getAutoCompleteLocation}
								>
									<DistanceSelect radius={10} />
								</AutoCompleteInput>
							</div>
						</div>
						<span className="button">
							<ActionButton type="submit" fat>
								Jobs Finden
							</ActionButton>
						</span>
					</div>
					<div className="row">
						<StoredSearches storedSearches={storedSearches} lang="de" />
					</div>
				</div>
			</Form>
		</div>
		<div className="sections">
			<Advantages />
			<MarketingNumbers {...jobIndex} />
		</div>
		<SeoBlockStartpage seoStartPage={seoStartPage} jobIndex={jobIndex} />
		<Footer />
		<style jsx>{`
			.hero {
				background-image: url(/searchformBackgroundDesktop.jpg);
				height: 510px;
				background-size: cover;
				background-position-y: bottom;
				border-bottom: 14px solid rgb(170, 204, 0);
				position: relative;
			}
			.container {
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				left: 35%;
				display: flex;
				flex-direction: column;
				background: rgba(86, 86, 86, 0.5);
				border: 1px solid rgba(226, 226, 226, 0.9);
				padding: 40px;
			}
			.row {
				display: flex;
				flex-direction: row;
				align-items: flex-end;
				left: 35%;
				width: 100%;
			}

			label {
				font-weight: 500;
				font-size: 24px;
				color: white;
				margin-bottom: 13px;
				display: block;
				padding-bottom: 5px;
			}

			.input,
			.button {
				width: 400px;
				height: 40px;
				margin-bottom: 26px;
			}
			.input {
				flex-shrink: 1;
				flex-grow: 1;
			}

			@media (max-width: 700px) {
				.hero {
					background-image: url(/searchformBackgroundMobile.jpg);
					background-position: center center;
					background-repeat: no-repeat;
					border-bottom: 0;
					height: initial;
				}
				label {
					display: none;
				}
				.container > div,
				.button {
					margin: 0 auto;
				}
				.input,
				.button {
					width: calc(100vw - 40px);
					max-width: 400px;
					margin-left: auto;
					margin-right: auto;
					margin-bottom: 20px;
				}
				.container {
					position: static;
					padding: 0;
					padding-top: 60%;
					padding-bottom: 50px;
					transform: none;
					border: none;
					background: none;
				}
				.row {
					align-items: center;
				}
			}

			.sections {
				display: flex;
				flex-direction: column;
			}

			@media (min-width: 1280px) {
				.container {
					width: 1080px;
					flex-direction: column;
					left: 50%;
					transform: translate(-50%, -50%);
					align-items: flex-end;
				}

				.container .row > div:nth-child(2) {
					margin: 0 20px;
				}
			}

			@media (max-width: 1279px) {
				.container .row {
					flex-direction: column;
					max-width: 400px;
				}
			}
		`}</style>
	</>
)

export default Home
