import Link from 'next/link'
import { SeoLinkDescription } from '../interfaces'

const hrefFromSeoLink = (seoLink: string): string => {
	const paths: { [key: number]: string } = {
		[1]: '/[l1]',
		[2]: '/[l1]/[l2]',
		[3]: '/[l1]/[l2]/[l3]',
		[4]: '/[l1]/[l2]/[l3]/[l4]',
	}
	return paths[seoLink.split('/').length - 1]
}

type Props = {
	seoLink: SeoLinkDescription
}

const SeoLink: React.FC<Props> = ({ seoLink }) => (
	<Link href={hrefFromSeoLink(seoLink.LinkZiel)} as={seoLink.LinkZiel}>
		<a
			dangerouslySetInnerHTML={{
				__html: seoLink.LinkText,
			}}
		></a>
	</Link>
)

export default SeoLink
