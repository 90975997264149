import { useEffect, useRef, RefObject } from 'react'

type Props = Readonly<{
	count: number
}>

const AnimatedCounter: React.FC<Props> = ({ count }) => {
	const ref = useAnimatedNode(createSigmoidNumberAnimation(count))

	return (
		<>
			<i ref={ref}>
				&nbsp;
				<noscript>{count.toLocaleString('de').replace(/,/g, '.')}</noscript>
			</i>
			<style jsx>{`
				i {
					font-style: normal;
				}
			`}</style>
		</>
	)
}

type AnimationFunction = (node: HTMLElement, now: number) => boolean

export const createSigmoidNumberAnimation = (count: number): AnimationFunction => {
	let currentCount = 1
	// https://en.wikipedia.org/wiki/Sigmoid_function
	const sigmoid = (x: number) => 1 / (1 + Math.pow(Math.E, -x))
	// this is a value close to 1 which is causes
	// asymptoticalMax * count ~= count
	const asymptoticalMax = parseFloat(`0.${'9'.repeat(count.toString().length - 1)}`)
	// use inverse sigmoid to get xMax so that
	// sigmoid(xMax) * currentCount ~= count
	const xMax = Math.log(asymptoticalMax / (1 - asymptoticalMax))
	const xMin = -xMax
	let x = xMin
	const durationMs = 3000
	let startMs: number
	const getX = (now: number) => xMin + (xMax - xMin) * ((now - startMs) / durationMs)
	return (node: HTMLElement, now: number): boolean => {
		startMs = startMs || now
		x = getX(now)
		currentCount = Math.ceil(Math.min(count, count * sigmoid(x)))
		node.innerText = currentCount.toLocaleString('de')
		return currentCount < count
	}
}

const useAnimatedNode = (animate: AnimationFunction): RefObject<HTMLElement> => {
	const ref = useRef<HTMLElement>(null)
	useEffect(() => {
		let animationFrame = 0
		const isVisible = (node: HTMLElement) => {
			const { top, bottom } = node.getBoundingClientRect()
			const clientHeight =
				window.innerHeight || document.documentElement.clientHeight
			return bottom >= 0 && top <= clientHeight
		}

		const onScroll = () => {
			if (ref && ref.current && isVisible(ref.current)) {
				startAnimation(ref.current)
			}
		}

		const startAnimation = (node: HTMLElement) => {
			window.removeEventListener('scroll', onScroll)
			const update = () => {
				if (animate(node, Date.now())) {
					animationFrame = requestAnimationFrame(update)
				}
			}
			animationFrame = requestAnimationFrame(update)
		}

		window.addEventListener('scroll', onScroll)
		if (ref && ref.current && isVisible(ref.current)) {
			startAnimation(ref.current)
		}

		return () => {
			window.removeEventListener('scroll', onScroll)
			cancelAnimationFrame(animationFrame)
		}
	}, [])
	return ref
}

export default AnimatedCounter
