import { Loading } from './Icons'
import { accentColor } from 'config/style'

type Props = {
	isLoading?: boolean
	onClick?: () => void
	type: React.ComponentProps<'button'>['type']
	fat?: boolean
}

const ActionButton: React.FC<Props> = ({ children, isLoading, onClick, type, fat }) => (
	<button
		type={type}
		onClick={onClick}
		disabled={isLoading}
		className={isLoading ? 'loading' : ''}
	>
		<span>
			<Loading />
		</span>
		{children}
		<style jsx>{`
			button {
				position: relative;
				background: ${accentColor};
				color: white;
				width: 100%;
				height: 100%;
				font-size: ${fat ? '18' : '16'}px;
				font-weight: ${fat ? '500' : 'normal'};
				border-radius: 5px;
				outline: none;
				border-color: transparent;
				cursor: pointer;
				-webkit-tap-highlight-color: transparent;
			}
			button span {
				position: absolute;
				top: 50%;
				left: 50%;
				width: 28px;
				height: 28px;
				transform: translate(-50%, -50%);
				opacity: 0;
			}
			button.loading {
				color: transparent;
			}
			button.loading span {
				opacity: 1;
			}
		`}</style>
	</button>
)

export default ActionButton
