import { placeholder } from './styles'
import { accentColor as focusColor } from 'config/style'

const Input: React.FC<React.ComponentProps<'input'>> = props => (
	<>
		<input {...props} />
		<style jsx>{`
			input {
				border: 1px solid rgb(226, 226, 226);
				font-size: 16px;
				height: 40px;
				width: 100%;
				outline: none;
				padding-left: 10px;
				padding-right: 10px;
				background: rgb(244, 243, 243);
				appearance: none;
			}

			input[type='search'] {
				border-radius: 5px;
			}

			input:focus {
				border: 1px solid ${focusColor};
			}

			input::placeholder {
				font-style: ${placeholder.fontStyle};
				color: ${placeholder.color};
			}
		`}</style>
	</>
)

export default Input
