import {
	getJobIndex,
	getStoredSearches,
	getNotedOffers,
	getSeoStartPage,
	isUserLoggedIn,
} from 'shared/api'
import { IndexPageProps } from 'shared/interfaces'
import { asPath } from 'shared/Util'
import { NextPageContext } from 'next'

export default async (ctx: NextPageContext): Promise<IndexPageProps> => {
	const [storedSearches, jobIndex, notedOffers, seoStartPage] = await Promise.all([
		getStoredSearches(ctx),
		getJobIndex(ctx),
		getNotedOffers(ctx),
		getSeoStartPage(ctx),
	])

	return {
		asPath: asPath(ctx),
		jobIndex,
		storedSearches,
		notedOffers,
		seoStartPage,
		isUserLoggedIn: isUserLoggedIn(ctx),
		query: ctx.query,
	}
}
